
body
{
font-family: 'Open Sans';
font-size: 16px;
}

main
{
    width: 100%;
}

h1
{
    text-align: center;
    margin:5px;
}


.jw-state-idle .jw-controls {
    background-color: transparent !important;
}

.jw-controls-backdrop {
    background: none !important;
}

.jw-display-icon-display {
    border-color: white !important;
    border-width: 4px !important;
    border-style: solid !important;
    border-radius: 50% !important;
    background-color: #ff000050 !important;
}

