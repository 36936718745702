@import '../styles/definitions.scss';

.masthead
{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
}

@media only screen and (min-width: $headerBreakpoint)
{

    .navbarLong {
        display: flex;
        flex-direction: column;
        background-color:$navColor;
        //margin-left:auto;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        ul
        {
            width:100%;
            display: flex;
            flex-direction: row;        
            list-style-type: none;
            flex-wrap: wrap;
            margin-top:0px;
            margin-bottom: 0px;
            align-items: center;
            padding-left:0px;
            :last-child
            {
                margin-left: auto;
            }
            a
            {
        
                color: white;
                text-decoration: none;                      
            }
        }
        li
        {
            line-height: 30px;
            padding-left: 5px;
            padding-right: 5px;
            margin-left: 8px;
            margin-right: 8px;
            display: flex;
            flex-direction: row;
            flex-wrap:nowrap;
            //height: 100%;
            align-items: center;
            font-size: 14px;
                  
        }

        .topBar
        {
            height:40px;
            display:none;                
        }     

      

    }

    .navbarShort
    {
        display:none;
    }
}

@media only screen and (max-width: $headerBreakpoint)
{
    .navbarShort {
        display: flex;
        flex-direction: column;

       // margin-left:auto;
       width: 100%;
        ul
        {
            display: flex;
            flex-direction: column;        
            list-style-type: none;
            flex-wrap: wrap;
            margin-top:0px;
            margin-bottom: 0px;
            padding-bottom:8px;
            align-items: flex-start;
            flex-grow:1;
            padding-left:0px;
            background-color: $navColor;

            a
            {
        
                color: white;
                text-decoration: none;                      
            }
          
        }
        li
        {
            line-height: 40px;
            padding-left: 10px;
            padding-right: 10px;
            margin-left: 10px;
            margin-right: 10px;
            display: flex;
            flex-direction: row;
            flex-wrap:nowrap;
            //height: 100%;
            align-items:flex-start;
            
              
        }
        .topBar
        {
            height:50px;
            align-items:center;
            justify-content: flex-end;
            background-color:$navColor;
            color:white;
            display: flex;
            padding: 4px;
           padding-right: 10px;
        } 

     


    }


    .navbarLong
    {
        display:none;
    }
}

.selected
    {
        background-color: #c1b3a2;
    }

.highlit {
    background-color: #b02929;
}

.header
{
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    width: 100%;
    max-width: $maxHeaderWidth;
    margin: 10px 10px 0px 10px;
    .sitelogo
    {
        flex-shrink: 0;
        flex-grow:0;

        margin-right:10px;
    }
    
}


.extraWide
{
    max-width: 100%;
}

