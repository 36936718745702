@import '../styles/definitions.scss';

.wp-gallery
{
    display: flex;
    flex-direction: row;
    column-gap:5px;
    row-gap:5px;

    @media only screen and (max-width: $smBreakpoint) {
        flex-wrap: wrap;
        flex-direction: column;
       }

    img
    {
        width: 100%;
        //this breaks the aspect ratio
        //max-height: 450px;
    }
}

figure
{
    margin:0px;
}

.wp-blockquote
{
    padding-left: 40px;
    margin: 0;
    border-left: 2px solid #e06d5e;
    margin-bottom: 1em;
    margin-top:20px;
}

.wp-media-text
{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (max-width: 768px) 
    {
        flex-direction: column;
    }

    img
    {
        width:100%;
    }
}

.wp-media-text-flipped
{
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (max-width: 768px) 
    {
        flex-direction: column;
    }

    img
    {
        width:100%;
    }
 
}

.wp-image
{
    width:100%;
    border-radius: 5px;

}

.wp-block-image img {
    width: 100%;
}

.wp-image-container
{
    //margin: auto 5px auto 5px;
    //margin:auto;
    //flex:1;

   // margin:5px;
    //display: block;
    
    margin:auto;
    min-width: 120px;

    figcaption
    {
        text-align: center;
        font-size: 12px;
        font-style: italic;
    }

    @media only screen and (max-width: $smBreakpoint) {
        min-width: 300px;
       }

}

.wp-columns
{
    display: flex;
    justify-content: center;

    @media only screen and (max-width: $smBreakpoint) {
        flex-direction: column;
       }

    img
    {
        //messes with aspect ratios
       // max-height: 400px;
    }
}

.wp-column
{
    flex-basis: 0;
    flex-grow: 1;
    //display:flex;
    //justify-content: center;
    //flex-direction: column;
    //flex-shrink: 1;
    //align-items: center;

}

.rus-author-credit
{
    font-style: italic;
    background-color: #ffffe8;
    padding: 12px;
    border-width: 2px 2px 2px 2px;
    border-style: solid;
}

.rus-caption {
    font-size: 12px;
    text-align: center;
    margin-top: 6px;
    font-style: italic;
}

.rus-callout2
{
border-style: dotted;
    border-color: red;
    margin: 5px;
    padding: 12px;
    border-width: 3px;
}

.wp-grid-container
{
    display: flex;
    direction: row;
    flex-wrap:wrap;
    justify-content: center;

  .item
    {
       // grid-row:span 1;
        margin:5px;
        border-radius: 5px;;
    }

}

.wp-button-container
{
    display: flex;
    justify-content: center;
}

.wp-button:hover
{
    background-color: #228be6;
}
.wp-button
{
    background-color: #5d8be1;
 
    padding: 8px;
    border-radius: 4px;

    a
    {
        color:white;
        text-decoration: none;
    }
}

.wp-fa-icon
{
    width: 1em;
    height: 1em;
    vertical-align: -.125em;
}

.wp-carousel-slide
{
    height: 400px;
    @media only screen and (max-width: $smBreakpoint) {
        height:200px;
       }
}

.rus-aspect-ratio-box
{
    height: 0;
    overflow: hidden;
    position: relative;
}

.rus-aspect-ratio-box-vertical
{
    width: 0;
    overflow: hidden;
    position: relative;
}

.rus-aspect-ratio-box-inside
{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

h1
{
    text-align: center;
}
